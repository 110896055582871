import axios from 'axios';

import { useState } from 'react';
import { Button } from 'react-bootstrap';

export const ContactPage = () => {
	const [Name, setName] = useState('');
	const [Email, setEmail] = useState('');
	const [Message, setMessage] = useState('');

	const [Submited, setSubmited] = useState(false);

	const handleSubmit = () => {
		setSubmited(false);

		axios
			.post('https://backend.breakingpoint.software/contact/create', {
				name: Name,
				email: Email,
				message: Message,
			})
			.then(({ data }) => {
				if (data.error) return alert(data.error);

				setSubmited(true);
			});
	};

	return !Submited ? (
		<div className='container' style={{ paddingTop: '45px' }}>
			<h1 className='h2 text-center'>Contact Us</h1>

			<div
				style={{
					paddingTop: '25px',
					width: '100%',
				}}
			>
				<form
					style={{
						width: '100%',
					}}
				>
					<div
						className='mb-4'
						style={{
							width: '100%',
						}}
					>
						<div>
							<label
								className='block text-gray-700 text-sm font-bold mb-2'
								htmlFor='name'
							>
								Name
							</label>
						</div>

						<div>
							<input
								className='w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring focus:ring-indigo-200'
								type='text'
								id='name'
								name='name'
								placeholder='Your Name'
								value={Name}
								onChange={(e) => setName(e.target.value)}
								style={{ width: '100%' }}
							/>
						</div>
					</div>
					<div className='mb-4'>
						<div>
							<label
								className='block text-gray-700 text-sm font-bold mb-2'
								htmlFor='name'
							>
								Email
							</label>
						</div>

						<div>
							<input
								className='w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring focus:ring-indigo-200'
								type='email'
								id='email'
								name='email'
								placeholder='Your Email'
								value={Email}
								onChange={(e) => setEmail(e.target.value)}
								style={{ width: '100%' }}
							/>
						</div>
					</div>

					<div className='mb-4'>
						<div>
							<label
								className='block text-gray-700 text-sm font-bold mb-2'
								htmlFor='message'
							>
								Inquiry Message
							</label>
						</div>

						<div>
							<textarea
								className='w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring focus:ring-indigo-200'
								id='message'
								name='message'
								rows='4'
								placeholder='Your Message'
								value={Message}
								onChange={(e) => setMessage(e.target.value)}
								style={{ width: '100%' }}
							></textarea>
						</div>
					</div>

					<div
						style={{
							width: '100%',
						}}
					>
						<Button
							style={{
								backgroundColor: 'rgba(75, 72, 74, 255)',
								width: '100%',
								fontWeight: '700',
							}}
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</div>
				</form>
			</div>
		</div>
	) : (
		<div className='text-center' style={{ paddingTop: '45px' }}>
			<h1 style={{ fontSize: '28px' }}>Thank you for contacting us.</h1>

			<p>We will contact you back shortly.</p>
		</div>
	);
};
