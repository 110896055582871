import 'bootstrap/dist/css/bootstrap.min.css'

import { Helmet } from 'react-helmet'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { LandingPage, HistoryPage, PortfolioPage, ContactPage } from './pages'

import { DefaultLayout } from './layouts'

import { Settings } from './settings'

function App() {
	const { body, navbar, buttons, cards } = Settings.colors

	return (
		<div>
			<Helmet>
				<style>{`
					body {
						background-color: ${body.background} !important;
						color: ${body.text} !important;
					}

					.card {
						background-color: ${'rgb(15, 15, 15)'} !important;
						// background-color: ${cards.background} !important;
						border: ${cards.border.size} solid ${cards.border.color} !important;
						color: ${cards.text} !important
					}

					.navbar {
						background-color: ${navbar.background} !important;
						color: ${navbar.color} !important;
					}

					.navbar h1 {
						color: ${navbar.title} !important;
					}

					.btn-primary {
						background-color: ${buttons.primary.background} !important;
						border: ${buttons.primary.border.size} solid ${
					buttons.primary.border.color
				} !important;
						color: ${buttons.primary.color} !important;
					}

					.navbarContainer {
						width: 80vw !important;
						padding: 0 !important;
					}

					::-webkit-scrollbar {
						width: 8px; /* 1px wider than Lion. */
						/* This is more usable for users trying to click it. */
						background-color: rgba(0, 0, 0, 0);
						-webkit-border-radius: 100px;
					}
					/* hover effect for both scrollbar area, and scrollbar 'thumb' */
					::-webkit-scrollbar:hover {
						background-color: rgba(0, 0, 0, 0.09);
					}
					
					/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
					::-webkit-scrollbar-thumb:vertical {
						/* This is the EXACT color of Mac OS scrollbars. 
						   Yes, I pulled out digital color meter */
						background: rgba(0, 0, 0, 0.5);
						-webkit-border-radius: 100px;
					}
					::-webkit-scrollbar-thumb:vertical:active {
						background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
						-webkit-border-radius: 100px;
					}

					#specialRed {
						background-color: rgb(112, 0, 0) !important;
						border-color: rgb(112, 0, 0) !important;
					}

					.accordion-item {
						border-radius: 0 !important;
					}

					.accordion-header button {
						background-color: #0D6EFD !important;
						border-radius: 0 !important;
						color: #eee !important;
						font-weight: 700;
					}
				`}</style>
			</Helmet>

			<BrowserRouter>
				<Switch>
					<Route path='/' exact component={LandingPage} />

					<Route
						path='/history'
						component={() => (
							<DefaultLayout>
								<HistoryPage />
							</DefaultLayout>
						)}
					/>

					<Route
						path='/portfolio'
						component={() => (
							<DefaultLayout>
								<PortfolioPage />
							</DefaultLayout>
						)}
					/>

					<Route
						path='/contact'
						component={() => (
							<DefaultLayout>
								<ContactPage />
							</DefaultLayout>
						)}
					/>
				</Switch>
			</BrowserRouter>
		</div>
	)
}

export default App
