import { isMobile } from 'react-device-detect';

export const HistoryPage = () => {
	return (
		<div className='container' style={{ paddingTop: '45px' }}>
			<h1 className='h2 text-center'>Company History</h1>

			<p
				className='text-center'
				style={{
					fontSize: '24px',
					padding: '15px',
					paddingTop: '0',
					marginTop: '0',
				}}
			>
				Last Updated on: 6/18/23
			</p>

			<div
				style={{
					textAlign: 'left',
					width: isMobile ? '95%' : '80%',
					margin: 'auto',
				}}
			>
				<p style={{ textIndent: '45px', fontSize: '22px' }}>
					Breaking Point Software was founded in 2022 with a clear
					vision and ambitious goals. The company set out to meet the
					demands of local businesses by providing innovative and
					customized software solutions. With a passionate team at its
					core, Breaking Point Software is dedicated to empowering
					businesses and helping them thrive in today's
					technology-driven world. The company's commitment to
					customer satisfaction and staying at the forefront of
					technological advancements drives its continuous growth and
					success.
				</p>

				<p style={{ fontSize: '22px', paddingTop: '15px' }}>
					- Jacob S (Founder)
				</p>
			</div>
		</div>
	);
};
