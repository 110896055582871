export const Settings = {
	type: 'store',
	store: {
		name: 'Breaking Point Software',
		motto: '',
		enabled: true,
		navbar: {
			showLogo: true,
			stickyNavbar: true,
			links: [
				{
					label: 'Home',
					destination: '/',
					alignment: 'left',
				},
				{
					label: 'Products',
					destination: '/products',
					alignment: 'left',
				},
			],
		},
		carousels: [
			{
				name: 'Item #1',
				caption: 'Item #1',
				image: 'https://wallpapercave.com/wp/wp9530079.jpg',
			},
			{
				name: 'Item #2',
				caption: 'Item #2',
				image: 'https://i.imgur.com/TJgK3jR.jpg',
			},
		],
		alerts: [],
		stats: { customers: 0, products: 1, sales: 0 },
		logo: '/logo.png',
	},
	colors: {
		body: { background: 'rgb(15, 15, 15)', text: '#eee' },
		buttons: {
			primary: {
				background: 'rgb(237, 109, 36)',
				text: '#eee',
				border: { size: '3px', color: 'rgb(237, 109, 36)' },
			},
			secondary: {
				background: '#',
				text: '#',
				border: { size: '', color: '#' },
			},
		},
		navbar: {
			background: 'rgb(15, 15, 15)',
			text: '#eee',
			border: { size: '5px', color: 'rgb(237, 109, 36)' },
		},
		cards: {
			background: '#36393fff',
			text: '#eee',
			border: { size: '3px', color: 'rgb(237, 109, 36)' },
		},
	},
}
