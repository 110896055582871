import { Container, Navbar } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export const Footer = () => {
    const text = 'white';

    return <div className='footer' style={{ /*height: "240px",*/ width: "100%", backgroundColor: 'rgb(15, 15, 15)', borderTop: '5px solid rgb(237, 109, 36)' }}>
        <Container
            fluid={false}
            className={false ? 'navbarContainer' : null}
            style={{
                marginTop: '5px',
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div style={{ paddingTop: '20px', display: isMobile ? 'block' : 'flex', width: '100%', paddingBottom: '30px' }}>
                <div
                    style={{
                        width: isMobile ? '100%' : "33.33%"
                    }}
                >
                    <a
                        href='/'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0',
                            margin: '0',
                            textDecoration: 'none',
                        }}
                    >
                        <Navbar.Brand
                            style={{
                                // display: 'flex',
                                // alignItems: 'center',
                                padding: '0',
                                margin: '0',
                                width: isMobile ? '100%' : null
                            }}
                        >
                            <div style={{ textAlign: isMobile ? 'center' : null }}>
                                <img
                                    src={'/logo.png'}
                                    style={{
                                        height: '85px',
                                        marginRight: !isMobile ? '20px' : null,
                                        borderRadius: '5px',
                                    }}
                                />

                                <div>
                                    <h1
                                        className={
                                            'h4'
                                        }
                                        style={{
                                            padding: '0',
                                            margin: '0',
                                            color: text,
                                            fontSize: '18px',
                                            paddingTop: '15px'
                                        }}
                                    >
                                        {"Breaking Point Software"}
                                    </h1>

                                    <p
                                        style={{
                                            margin: '0',
                                            padding: '0',
                                            color: text,
                                            fontSize: '17px',
                                            paddingTop: '10px'
                                        }}
                                    >
                                        &copy; {new Date().getFullYear()}. All Rights Reserved
                                    </p>

                                    <p
                                        style={{
                                            margin: '0',
                                            padding: '0',
                                            color: text,
                                            fontSize: '17px',
                                            paddingTop: '10px'
                                        }}
                                    >
                                        Software Consulting & Web
                                        Development
                                    </p>
                                </div>
                            </div>
                        </Navbar.Brand>
                    </a>
                </div>

                <div style={{ width: isMobile ? '100%' : '33.33%', paddingTop: isMobile ? '25px' : null }}>
                    <div style={{ paddingLeft: !isMobile ? '15px' : null, textAlign: isMobile ? 'center' : null }}>
                        <h1 className="h4" style={{ fontSize: "20px", }}>Pages</h1>

                        <a href='/contact' style={{ textDecoration: 'none', color: '#eee' }}>
                            <h1 className="h4" style={{ fontSize: '16px', marginLeft: !isMobile ? '5px' : null, marginTop: '10px' }}>
                                <i className='fas fa-address-book'></i>{' '}
                                Contact Methods
                            </h1>
                        </a>
                    </div>
                </div>

                <div style={{ width: isMobile ? '100%' : '33.33%', paddingTop: isMobile ? '25px' : null }}>
                    <div style={{ paddingLeft: !isMobile ? '15px' : null, textAlign: isMobile ? 'center' : null }}>
                        <h1 className="h4" style={{ fontSize: "20px", }}>Socials</h1>

                        <a target="_blank" href={process.env.REACT_APP_DISCORD} style={{ textDecoration: 'none', color: '#eee' }}>
                            <h1 className="h4" style={{ fontSize: '16px', marginLeft: '5px', marginTop: '10px' }}>
                                <i className='fab fa-discord'></i>{' '}
                                Discord
                            </h1>
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    </div>;
};