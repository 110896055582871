import { Footer, SiteNavbar } from '../components';

export const DefaultLayout = ({ children }) => {
	return (
		<>
			<div style={{ height: "100vh" }}>
				<SiteNavbar />

				<div
					className='container'
					style={{
						marginTop: '100px',
					}}
				>
					{children}
				</div>
			</div>

			<Footer />
		</>
	);
};
