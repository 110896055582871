import { Card, Container, Button } from 'react-bootstrap';

export const PortfolioPage = () => {
	return (
		<Container
			style={{
				paddingTop: '45px',
			}}
		>
			<h1 className='h2 text-center'>Company Portfolio</h1>

			<div
				className='row'
				style={{
					paddingTop: '35px',
				}}
			>
				<div className='col-md-4'>
					<Card body>
						<Card.Img src='https://i.imgur.com/CymqGZb.png' />

						<Card.Title
							style={{
								paddingTop: '20px',
								paddingBottom: '10px',
							}}
						>
							American Steel Fighting League
						</Card.Title>

						<hr />

						<Button
							target='_blank'
							href='https://www.americansteelfightingleague.com/'
							style={{
								width: '100%',
								fontWeight: '700',
								marginTop: '5px',
							}}
						>
							Visit Site
						</Button>
					</Card>
				</div>

				<div className='col-md-4' style={{ paddingBottom: '20px' }}>
					<Card body>
						<Card.Img src='https://i.imgur.com/ucf6zlU.png' />

						<Card.Title
							style={{
								paddingTop: '20px',
								paddingBottom: '10px',
							}}
						>
							E & B Mobile Detailing
						</Card.Title>

						<hr />

						<Button
							target='_blank'
							href='https://ebdetailing.net'
							style={{
								width: '100%',
								fontWeight: '700',
								marginTop: '5px',
							}}
						>
							Visit Site
						</Button>
					</Card>
				</div>

				<div className='col-md-4'>
					<Card body>
						<Card.Img src='https://i.imgur.com/d2mUBC1.png' />

						<Card.Title
							style={{
								paddingTop: '20px',
								paddingBottom: '10px',
							}}
						>
							Arzaga Painting
						</Card.Title>

						<hr />

						<Button
							target='_blank'
							href='https://arzagapainting.com'
							style={{
								width: '100%',
								fontWeight: '700',
								marginTop: '5px',
							}}
						>
							Visit Site
						</Button>
					</Card>
				</div>
			</div>
		</Container>
	);
};
