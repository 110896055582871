import { Helmet } from 'react-helmet';
import { SiteNavbar, LandingCard, Footer } from '../components';

import { isMobile } from 'react-device-detect';

export const LandingPage = () => {
	return (
		<div>
			<Helmet>
				<style>
					{`body { overflow-x: hidden !important; }`}
				</style>
			</Helmet>

			<div>
				<SiteNavbar />
			</div>

			<div
				style={{
					height: '100vh',
					width: '100vw',
					overflow: 'auto',
					overflowX: 'hidden',
					background: 'black',
					// position: 'fixed',
				}}
			>
				<div
					style={{
						position: 'absolute',
						width: '100vw',
						height: '100vh',
						zIndex: '1',
					}}
				>
					<img
						src='/background.jpg'
						onContextMenu={(e) => e.preventDefault()}
						style={{
							objectFit: 'cover',
							opacity: '0.4',
							overflow: 'auto',
							overflowX: 'hidden',
							height: '100vh',
							width: '100vw',
							zIndex: '200',
						}}
					/>
				</div>
				<div
					style={{
						position: 'absolute',
						top: '100px',
						left: '0%',
						width: '100vw',
						opacity: '1.0',
						zIndex: '300',
						height: 'calc(100vh - 100px)',
						overflow: isMobile ? "hidden" : 'auto',
					}}
				>
					<div
						className='container'
						style={{
							marginTop: isMobile ? '20px' : '55px',
							marginBottom: isMobile ? '65px' : '25px',
						}}
					>
						<div
							style={{
								textAlign: 'center',
								width: isMobile ? '90%' : null,
								margin: isMobile ? 'auto' : null
							}}
						>
							<h1 className='h3'>Top Grade Solutions, Tailored for Your Business.</h1>

							<p>Your Partner in Software Consulting & Web Development Excellence.</p>
						</div>

						<div
							className='row'
							style={{
								paddingTop: isMobile ? '10px' : '45px'
							}}
						>
							{/* <div className='col-md-6'>
								<LandingCard
									href='/commissions'
									icon={<i className='fas fa-images'></i>}
									title='Custom Commissions'
									description='Have a specific project in mind? Our team is ready to bring your vision to life with bespoke digital creations, tailored to meet your exact requirements.'
								/>
							</div> */}

							{/* <div className='col-md-6'>
								<LandingCard
									href='/portfolio'
									icon={<i className='fas fa-images'></i>}
									title='Portfolio'
									description='See our past work.'
								/>
							</div> */}

							{/* <div className='col-md-4'>
								<LandingCard
									href='/history'
									icon={<i className='fas fa-users'></i>}
									title='Company History'
									description='View the company history.'
								/>
							</div> */}
							{!isMobile && <div className="col-md-2"></div>}
							<div className='col-md-4'>
								<LandingCard
									href='/contact'
									icon={
										<i className='fas fa-address-book'></i>
									}
									title='Contact Methods'
									description='Find methods of contacting us.'
								/>
							</div>
							<div className='col-md-4'>
								<LandingCard
									tag='a'
									href={process.env.REACT_APP_DISCORD}
									icon={<i className='fab fa-discord'></i>}
									title='Discord Community'
									description='Join the offical BP Discord.'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div >
	);
};
