import { Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

export const LandingCard = ({ icon, title, description, href, tag }) =>
	tag ? (
		<a href={href} style={{ textDecoration: 'none' }} target='_blank'>
			<Card
				className='hoverableCard'
				body
				style={{
					cursor: 'pointer',
					backgroundColor: 'rgb(15, 15, 15)',
					marginBottom: isMobile ? '20px' : '30px',
				}}
			>
				<div style={{ textAlign: 'center' }}>
					<Card.Title style={{ fontSize: '85px' }}>{icon}</Card.Title>

					<Card.Title>{title}</Card.Title>

					<p>{description}</p>
				</div>
			</Card>
		</a>
	) : (
		<a href={href} style={{ textDecoration: 'none' }}>
			<Card
				body
				className='hoverableCard'
				style={{
					cursor: 'pointer',
					backgroundColor: 'rgb(15, 15, 15)',
					marginBottom: isMobile ? '20px' : '30px',
				}}
			>
				<div style={{ textAlign: 'center' }}>
					<Card.Title style={{ fontSize: '85px' }}>{icon}</Card.Title>

					<Card.Title>{title}</Card.Title>

					<p>{description}</p>
				</div>
			</Card>
		</a>
	);
