import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Settings } from '../settings';

export const SiteNavbar = () => {
	const { logo, name, navbar } = Settings.store;

	const { border, text } = Settings.colors.navbar;

	const [ShowDropdown, setShowDropdown] = useState(false);

	const fluid = false;

	const siteAdmin = false;

	return (
		<>
			{ShowDropdown && (
				<div
					style={{
						height: '100vh',
						width: '100vw',
						zIndex: '1000',
						position: 'fixed',
						backgroundColor: 'rgb(15, 15, 15)',
						top: '0',
					}}
				>
					<div className='container'>
						<div
							style={{
								height: '100px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<h1>Breaking Point Software</h1>

							<div style={{ marginLeft: 'auto' }}>
								<h1
									style={{
										cursor: 'pointer',
										padding: '5px',
									}}
									onClick={() => setShowDropdown(false)}
								>
									<i class='fas fa-times'></i>
								</h1>
							</div>
						</div>

						<div style={{ paddingTop: '30px' }}>
							<div className='h3'>
								<a
									href='/'
									onClick={() => setShowDropdown(false)}
									style={{
										textDecoration: 'none',
										fontSize: '24px',
									}}
								>
									Home
								</a>
							</div>

							<div className='h3'>
								<a
									href='/'
									onClick={() => setShowDropdown(false)}
									style={{
										textDecoration: 'none',
										fontSize: '24px',
									}}
								>
									Home
								</a>
							</div>

							<div className='h3'>
								<a
									href='/'
									onClick={() => setShowDropdown(false)}
									style={{
										textDecoration: 'none',
										fontSize: '24px',
									}}
								>
									Home
								</a>
							</div>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					position: 'absolute',
					width: '100%',
					zIndex: '10000',
					top: '0',
				}}
			>
				<Navbar
					bg='light'
					sticky='top'
					style={{
						borderBottom: border.size + ' solid ' + border.color,
						height: '100px',
						color: text,
					}}
				>
					<Container
						fluid={fluid}
						className={fluid ? 'navbarContainer' : null}
						style={{
							marginTop: '5px',
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<div>
								<a
									href='/'
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0',
										margin: '0',
										textDecoration: 'none',
									}}
								>
									<Navbar.Brand
										style={{
											display: 'flex',
											alignItems: 'center',
											padding: '0',
											margin: '0',
										}}
									>
										{logo && navbar.showLogo && (
											<img
												src={logo}
												style={{
													height: '50px',
													marginRight: isMobile
														? '15px'
														: '20px',
													borderRadius: '5px',
												}}
											/>
										)}

										<div
											style={{
												paddingTop: '5px',
												paddingBottom: '5px',
											}}
										>
											<h1
												className={
													isMobile ? 'h2' : 'h4'
												}
												style={{
													padding: '0',
													margin: '0',
													color: text,
													fontWeight: isMobile
														? '600'
														: null,
												}}
											>
												{name}
											</h1>

											{!isMobile && (
												<p
													style={{
														margin: '0',
														padding: '0',
														color: text,
													}}
												>
													Software Consulting & Web
													Development
												</p>
											)}
										</div>
									</Navbar.Brand>
								</a>
							</div>
						</div>

						<div
							className='justify-content-end'
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{(navbar.links || []).map((link, i) => {
								if (link.alignment !== 'right') return;

								if (link.permission == 'admin' && !siteAdmin)
									return;

								// if (link.permission == 'loggedIn' && !user)
								// 	return;

								return (
									<Nav.Item key={i}>
										{link.permission == 'admin' ? (
											<a
												className='nav-link'
												href={
													window.location.origin +
													link.destination
												}
												style={{
													fontWeight: '700',
													fontSize: '16px',
													marginRight: '15px',
													color: text,
												}}
												target={
													link.openTab
														? '_blank'
														: null
												}
											>
												{link.label}
											</a>
										) : (
											<a
												className='nav-link'
												href={link.destination}
												style={{
													fontWeight: '700',
													fontSize: '16px',
													marginRight: '15px',
													color: text,
												}}
											>
												{link.label}
											</a>
										)}
									</Nav.Item>
								);
							})}

							{/* {user && (
								<Nav.Item>
									<Link
										to='/cart'
										className='nav-link'
										style={{
											fontWeight: '700',
											fontSize: '16px',
											color: '#eee',
											marginRight: '25px',
										}}
									>
										<i className='fas fa-shopping-cart'></i>
									</Link>
								</Nav.Item>
							)} */}
						</div>
					</Container>
				</Navbar>
			</div>
		</>
	);
};
